import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';


//EXPERIMENTAL
import { TabContentComponent } from "./tab-content.component";
import { ContentContainerDirective } from "./content-container.directive";
import { TabService } from "./tab.service";
//EXPERIMENTAL
// import { PaintingsComponent } from './paintings/paintings.component';

import { NgxMasonryModule } from 'ng-masonry-layout';
import { MasonryComponent } from './masonry/masonry.component';
import { PubsComponent } from './pubs/pubs.component';
import { CodingComponent } from './coding/coding.component';
import { IllosComponent } from './illos/illos.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';

const appRoutes: Routes = [
  { path: 'masonry', component: MasonryComponent },
  { path: 'pubs', component: PubsComponent },
  { path: 'illos', component: IllosComponent },
  { path: 'coding', component: CodingComponent }
  // { path: 'paintings', component: PaintingsComponent }
  // ,
  // { path: 'hero/:id',      component: HeroDetailComponent },
  // {
  //   path: 'heroes',
  //   component: HeroListComponent,
  //   data: { title: 'Heroes List' }
  // },
  // { path: '',
  //   redirectTo: '/heroes',
  //   pathMatch: 'full'
  // },
  // { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    MasonryComponent,
    PubsComponent,
    CodingComponent,
    // PaintingsComponent,
    IllosComponent,
    FooterComponent,
    AboutComponent,
    TabContentComponent,
    ContentContainerDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    MatButtonModule,
    MatTabsModule,
    MatListModule,
    NgxMasonryModule,
    MasonryGalleryModule,
    RouterModule
    // ,
    // Routes
  ],
  providers: [TabService],
  bootstrap: [AppComponent],
  entryComponents: [
    MasonryComponent,
    PubsComponent,
    CodingComponent,
    IllosComponent,
    FooterComponent,
    AboutComponent
  ]
})
export class AppModule { }
