&nbsp;
<div class="content"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-around stretch">
<div fxFlex></div>
<div fxFlex="30" class="sec1" >
  <mat-card class="example-card">
  <mat-card-header>
    <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
    <mat-card-title>Team-Based Care</mat-card-title>
    <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
  </mat-card-header>
  <!-- <div class="center">  -->
    <img src="assets/coding/cpn.jpg" alt="Team-Based Care">
  <!-- </div> -->
  <mat-card-content class="coding">
    <p>
      A website created to present a model of patient care that involves increased communication and collaboration between healthcare providers. - The website features an interactive 3D community that lets the user explore the principles of team-based care and walk through a scenario in which various healthcare providers cooperate to provide comprehensive services to an individual patient.
      </p>
    <strong>Instructional Designer: </strong>Catherine Macallister<br />
    <strong>Design, UI/UX development and programming: </strong>Jason Whitley<br />
    <strong>Graphics and 3D models: </strong>Joel Bethea Floyd, jr.<br />
    <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
  </mat-card-content>
  <mat-card-actions>
    <a href="https://apps.media.unc.edu/cpf" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
    <!-- <button mat-button>SHARE</button> -->
  </mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
<div fxFlex="30" class="sec1" >
<mat-card class="coding">
<mat-card-header style="margin-left: -16px;">
  <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
  <mat-card-title>NC Controlled Substances Reporting System</mat-card-title>
  <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
</mat-card-header>
<!-- <div class="center">  -->
  <img src="assets/coding/csrs.jpg" alt="Team-Based Care">
<!-- </div> -->
<mat-card-content style="text-algn: left;">
  <p style="text-algn: left;">A continuing education resource for North Carolina pharmacists, built using Angular Bootstrap.</p>
  <p>The North Carolina Controlled Substance Reporting System (CSRS) was established by North Carolina law to improve the state’s ability to track prescribing and dispensing patterns of controlled substances in North Carolina. The tracking of controlled substance prescribing and dispensing is part of a larger effort to curb the abuse and misuse of prescription drugs by North Carolinians. This training program introduces you to the CSRS.</p>

  <strong>Instructional Designer: </strong>Catherine Macallister and Patrick Brown<br />
  <strong>Design, UI/UX development and programming: </strong>Jason Whitley<br />
  <strong>Videography: </strong>Kevin Robinson<br />
  <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
</mat-card-content>
<mat-card-actions>
  <a href="https://apps.media.unc.edu/csrs" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
  <!-- <button mat-button>SHARE</button> -->
</mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
<div fxFlex="30" class="sec1" >
<mat-card class="example-card coding">
<mat-card-header style="margin-left: -16px;">
  <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
  <mat-card-title>High-Performace Liquid Chromatogram</mat-card-title>
  <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
</mat-card-header>
<!-- <div class="center">  -->
  <img src="assets/coding/hplc.jpg" alt="High-Performance Liquid Chromatrogram">
<!-- </div> -->
<mat-card-content>
  <p>A continuing education resource for North Carolina pharmacists, built using Angular Bootstrap.</p>
  <p>The North Carolina Controlled Substance Reporting System (CSRS) was established by North Carolina law to improve the state’s ability to track prescribing and dispensing patterns of controlled substances in North Carolina. The tracking of controlled substance prescribing and dispensing is part of a larger effort to curb the abuse and misuse of prescription drugs by North Carolinians. This training program introduces you to the CSRS.</p>

  <strong>Instructional Designer: </strong>Laurence Orlando<br />
  <strong>Design, UI/UX development and programming: </strong>Jason Whitley<br />
  <strong>Algorithms: </strong>Carson Yuan<br />
  <strong>Graphics and 3D models: </strong>Joel Bethea Floyd, jr.<br />
  <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
</mat-card-content>
<mat-card-actions>
  <a href="https://apps.media.unc.edu/hplc" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
  <!-- <button mat-button>SHARE</button> -->
</mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
</div>

&nbsp;
<!-- Row 2, coding -->

<div class="content"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-around stretch">

<div fxFlex></div>
<div fxFlex="30" class="sec1" >
  <mat-card class="example-card coding">
  <mat-card-header style="margin-left: -16px;">
    <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
    <mat-card-title>Electronic Medical Records (EMR)</mat-card-title>
    <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
  </mat-card-header>
  <!-- <div class="center">  -->
    <img src="assets/coding/EMR.jpg" alt="Electronic M edical Record image">
  <!-- </div> -->
  <mat-card-content>
    <p>The EMR tool simulates real-world electronic medical records, such as EPIC. It includes virtual patients whose case histories are revealed over time. The EMR features a case editor that instructors can use to modify existing, and add new, virtual patients.</p>
    <strong>Development and programming: </strong>Jason Whitley<br />
    <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
  </mat-card-content>
  <mat-card-actions>
    <a href="https://shib.media.unc.edu/cases" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
    <!-- <button mat-button>SHARE</button> -->
  </mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
<div fxFlex="30" class="sec1" >
<mat-card class="example-card coding">
<mat-card-header style="margin-left: -16px;">
  <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
  <mat-card-title>Collabucate</mat-card-title>
  <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
</mat-card-header>
<!-- <div class="center">  -->
  <img src="assets/coding/collabucate.png" alt="Collabucate image">
<!-- </div> -->
<mat-card-content>
  <strong>Collabucate</strong> delivers <strong>personalized team strategies </strong> targeting <strong>Skill, Will, and Feel</strong> — the three keystones of teamwork, based on individual and team performance assessments.
  <strong>Instructional Designer: </strong>Kayley Lyons and Nikki Lobczowski<br />
  <strong>UI/UX development and programming: </strong>Jason Whitley<br />
  <strong>Design: </strong>Kevin Robinson<br />
  <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
</mat-card-content>
<mat-card-actions>
  <a href="https://shib.media.unc.edu/collab" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
  <!-- <button mat-button>SHARE</button> -->
</mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
<div fxFlex="30" class="sec1" >
<mat-card class="example-card coding">
<mat-card-header style="margin-left: -16px;">
  <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
  <mat-card-title>North Carolina Zoo Kiosks</mat-card-title>
  <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
</mat-card-header>
<!-- <div class="center">  -->
  <img src="assets/coding/zoo_kiosk.jpg" alt="North Carolina Zoo Kiosk image">
<!-- </div> -->
<mat-card-content>
  <p>Programming for multiple interactive kiosks at the N.C. Zoo, designed with the N.C. Zoo team.</p>
  <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
</mat-card-content>
<mat-card-actions>
  <!-- <a href="https://apps.media.unc.edu/hplc" target="_blank" mat-flat-button color="accent">View details &raquo;</a> -->
  <!-- <button mat-button>SHARE</button> -->
</mat-card-actions>
</mat-card>
</div>
<div fxFlex></div>
</div>
&nbsp; <!-- END CODING -->
