import { Component,OnInit, ViewChild } from '@angular/core';
import { MatTabsModule, MatTabGroup } from '@angular/material/tabs';
// import { MatTabGroup } from '@angular/material';
import { TabService } from "./tab.service";
import { Tab } from "./tab.model";
import { MasonryComponent } from './masonry/masonry.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
// @ViewChild('tabs', {static: false}) tabs: MatTabGroup;
tabs = new Array<Tab>();
 selectedTab: number;

 constructor(private tabService: TabService) {}


	public title = 'undercurrentart';

  // const tabGroup = this.tabs;


  // if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

  // const tabCount = tabGroup._tabs.length;
  // tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
// }
ngOnInit() {
	console.log('app');
	// console.log(this.tabs._tabs._results);
	this.tabService.tabSub.subscribe(tabs => {
		 this.tabs = tabs;
		 this.selectedTab = tabs.findIndex(tab => tab.active);
	 });
}

tabChanged(event) {
    console.log("tab changed");
		if(event.index==4) {
			console.log(event);
			this.tabs[4].tabData.layout=true;
    // new Tab(MasonryComponent, "Paintings", { parent: "AppComponent", layout: true });
		};

  }

  addNewTab() {
    this.tabService.addTab(
      new Tab(MasonryComponent, "Paintings", { parent: "AppComponent" })
    );
  }

  removeTab(index: number): void {
    this.tabService.removeTab(index);
  }

}


// public tabClick(tab) {
// 	console.log(tab);
// 	if (tab.index==4) {
// 		// this.router.navigate(['/', 'masonry']);
// 		// this.masonryOptions = {
// 		// 	initLayout: true,
// 		// 	resize: true,
// 		// 	percentPosition:true,
// 		// 	columnWidth: '.mat-card',
// 		// 	itemSelector: '.grid-item',
// 		// 	gutter: 10,
// 		// 	transitionDuration: '0.2s'
// 		// };
// 		// this.masonryImages = this.getpics().slice(0, this.limit);
// 	}
// 	else {console.log("not this tab");
// 	this.masonryOptions = {
// 		// initLayout: false,
// 		// resize: true,
// 		// percentPosition:false,
// 		// columnWidth: '.mat-card',
// 		// itemSelector: '.grid-item',
// 		// gutter: 20
// 	};
// 	this.masonryImages = null;
// }
// }
