/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./masonry.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-masonry-layout";
import * as i3 from "../../../node_modules/ng-masonry-layout/ng-masonry-layout.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./masonry.component";
var styles_MasonryComponent = [i0.styles];
var RenderType_MasonryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasonryComponent, data: {} });
export { RenderType_MasonryComponent as RenderType_MasonryComponent };
function View_MasonryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "masonry-item"], ["ngxMasonryItem", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.NgxMasonryDirective, [i1.ElementRef, i2.NgxMasonryComponent, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "className", 0], [8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.data.layout = !_co.data.layout) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["style", "color:#ffffff;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.class, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.picture, ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
export function View_MasonryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "height:1000px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ngx-masonry", [["height", "1000px"], ["width", "1000px"]], null, [[null, "layoutComplete"]], function (_v, en, $event) { var ad = true; if (("layoutComplete" === en)) {
        var pd_0 = (true !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NgxMasonryComponent_0, i3.RenderType_NgxMasonryComponent)), i1.ɵdid(2, 770048, null, 0, i2.NgxMasonryComponent, [i1.PLATFORM_ID, i1.ElementRef], { options: [0, "options"], updateLayout: [1, "updateLayout"] }, { layoutComplete: "layoutComplete" }), i1.ɵpod(3, { fitWidth: 0, resize: 1, initLayout: 2, transitionDuration: 3 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MasonryComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, true, true, "0.2s"); var currVal_1 = _co.data.layout; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.masonryItems; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MasonryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-masonry", [], null, null, null, View_MasonryComponent_0, RenderType_MasonryComponent)), i1.ɵdid(1, 114688, null, 0, i5.MasonryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasonryComponentNgFactory = i1.ɵccf("app-masonry", i5.MasonryComponent, View_MasonryComponent_Host_0, { data: "data" }, {}, []);
export { MasonryComponentNgFactory as MasonryComponentNgFactory };
