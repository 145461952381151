import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-illos',
  templateUrl: './illos.component.html',
  styleUrls: ['./illos.component.scss']
})
export class IllosComponent implements OnInit {

  constructor() { }


    public illoImages = this.getillos();

    getillos() {
    		return [
    			{
    				picture: 'assets/illos/getty.jpg',
    				title: 'Getty Images',
    				description: 'Many of the images on Getty are airy so that designers can work text into them.',
    				class: 'wide',
    				link: 'https://www.gettyimages.com/photos/jason-h.-whitley?phrase=jason%20h.%20whitley&sort=mostpopular'
    			},{
    				picture: 'assets/illos/jx.jpg',
    				title: 'Jack’s Christmas',
    				class: 'tall',
    				link: 'http://www.jackschristmas.com'
    			},{
    				picture: 'assets/illos/TMH.jpg',
    				title: 'Illustrations from The Midnight Hour series',
    				class: 'tall',
    				link: 'http://www.atthemidnighthour.com'
    			},{
    				picture: 'assets/illos/Baroness-final-COVER-small.jpg',
    				title: 'Baroness Von Darkclaw, commission',
    				class: 'tall'
    			},
    			{
    				picture: 'assets/illos/moresoon.jpg',
    				title: 'Still adding images here. Come back soon!',
    				class: 'wide'
    			}
    		]
    	};
  ngOnInit() {}



}
