<div class="example-small-box mat-elevation-z4">
<mat-toolbar color="accent"
   fxLayout="row"
   fxLayoutAlign="start center">
   <div >Sea Urchins</div>
</mat-toolbar>
&nbsp;
<div class="bounds">

  <div class="content"
       fxLayout="row"
       fxLayout.xs="column"
       fxLayoutAlign="space-around stretch">

      <div fxFlex></div>
        <mat-card fxFlex="20" class="example-card sec1" color="primary">
        <mat-card-header color="primary">
          <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
          <mat-card-title color="primary">Sea Urchins Book I</mat-card-title>
          <mat-card-subtitle color="accent">The Trouble with Oddo</mat-card-subtitle>
        </mat-card-header>
        <!-- <div class="center">  -->
          <img src="assets/illos/SU_cover_book_I.jpg" alt="Sea Urchins Book 3 cover">
        <!-- </div> -->
        <mat-card-content>

          <p>Available now!</p>
          <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
        </mat-card-content>
        <mat-card-actions>
          <a href="http://www.seaurchins.net" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
          <!-- <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      <!-- </div> -->
      <div fxFlex></div>
      <!-- <div fxFlex="33" class="sec2" > -->
        <mat-card fxFlex="20" class="example-card sec1">
        <mat-card-header>
          <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
          <mat-card-title>Sea Urchins Book II</mat-card-title>
          <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle>
        </mat-card-header>
        <!-- <div class="center">  -->
          <img src="assets/illos/SU_cover_book_II.jpg" alt="Sea Urchins Book 3 cover">
        <!-- </div> -->
        <mat-card-content>
          <p>Available now!</p>
          <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
        </mat-card-content>
        <mat-card-actions>
          <a href="http://www.seaurchins.net" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
          <!-- <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      <!-- </div> -->
<div fxFlex></div>
      <!-- <div fxFlex="33" class="sec3"> -->
        <mat-card fxFlex="20" class="example-card sec1">
        <mat-card-header>
          <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
          <mat-card-title>Sea Urchins Book III</mat-card-title>
          <mat-card-subtitle>The Blind, The Daft, and the Reptilian</mat-card-subtitle>
        </mat-card-header>
        <!-- <div class="center">  -->
          <img  src="assets/illos/SU_cover_book_III.jpg" alt="Sea Urchins Book 3 cover">
        <!-- </div> -->
        <mat-card-content>
          <p>Available now!</p>
          <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
        </mat-card-content>
        <mat-card-actions>
          <a href="http://www.seaurchins.net" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
          <!-- <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      <div fxFlex></div>
      <mat-card fxFlex="20" class="example-card sec1">
      <mat-card-header>
        <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
        <mat-card-title>Sea Urchins Book IV</mat-card-title>
        <mat-card-subtitle>So Long, Frozen Ocean</mat-card-subtitle>
      </mat-card-header>
      <!-- <div class="center">  -->
        <img src="assets/illos/SU_cover_book_IV.jpg" alt="Sea Urchins Book 4 cover">
      <!-- </div> -->
      <mat-card-content>
        <p>Coming in 2020!</p>
        <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
      </mat-card-content>
      <mat-card-actions>
        <a href="http://www.seaurchins.net" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
        <!-- <button mat-button>SHARE</button> -->
      </mat-card-actions>
    </mat-card>
    <div fxFlex></div>
</div> <!-- End Urchins -->
<div class="urchinstext">
    <p>Olie Unrun always wanted to live a life at sea. Instead, he worked at his dad's fish-oil factory and began the life of a family man. One day something in Olie snapped and he built a ship on his land... then he relocated his house to the deck of the ship and christened the whole shebang "The Banana Moon." All Olie had to do was wait for the right storm to lift his house to the sea.</p>
    <p>Now adrift, the Unruns piece together each day through a host of uncommon creatures and comic calamities.</p>
    <p>The Sea Urchins series collects the four-year run of the original newspaper comic strip. Each book includes a new 10-page color tale.</p>
  </div>
  &nbsp;
  <mat-toolbar color="accent"
         fxLayout="row"
         fxLayoutAlign="start center">
         <div>In Prison Series</div>
  </mat-toolbar>
  &nbsp;
  <div class="content"
       fxLayout="row"
       fxLayout.xs="column"
       fxLayoutAlign="space-around stretch">

      <div fxFlex="33" class="sec2">
        <mat-card class="example-card" color="primary">
        <mat-card-header color="primary">
          <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
          <mat-card-title color="primary">In Prison</mat-card-title>
          <!-- <mat-card-subtitle color="accent">The Trouble with Oddo</mat-card-subtitle> -->
        </mat-card-header>
        <!-- <div class="center">  -->
          <img style="width: 80%;" mat-card-image src="assets/illos/Prison_cover.png" alt="In Prison cover">
        <!-- </div> -->
        <mat-card-content>
          <p>Jamie Markham, Shane Tharrington, and Jason Whitley</p>
          <p><span class="quote">“The book is intended primarily as a reference for citizens who engage with the criminal justice system (as crime victims, defendants, or their family and friends), to help translate the words and numbers on a criminal judgment into a practical explanation of how long a person is likely to be incarcerated.”</span> – Jamie Markham</p>
          <p>Available now!</p>
        </mat-card-content>
        <mat-card-actions>
          <a href="https://www.sog.unc.edu/publications/books/prison-serving-felony-sentence-north-carolina-pack-10-books" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
          <!-- <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      </div>

      <div fxFlex="33" class="sec2" >
        <mat-card class="example-card">
        <mat-card-header>
          <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
          <mat-card-title>On Probation</mat-card-title>
          <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
        </mat-card-header>
        <!-- <div class="center">  -->
          <img style="width: 80%;" mat-card-image src="assets/illos/Probation_cover.png" alt="On Probation cover">
        <!-- </div> -->
        <mat-card-content>
          <p>Jamie Markham, Chad Owens, and Jason Whitley</p>
          <p><span class="quote">“This is the second issue in a series of graphic novels explaining North Carolina’s sentencing laws. Presenting the information in illustrated form is by no means intended to make light of a serious topic. It is, rather, offered as an accessible way to explain a complicated subject. It is meant to give crime victims, defendants, inmates, probationers, and their families an understandable resource that translates the words and numbers on a sentencing judgment into a practical reality. I hope it will be useful to judges, lawyers, and probation officers, too.”</span> – Jamie Markham</p>
          <p>Available now!</p>
          <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
        </mat-card-content>
        <mat-card-actions>
          <a href="https://www.sog.unc.edu/publications/books/probation-serving-probationary-sentence-north-carolina#!/" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
          <!-- <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      </div>
</div>
      &nbsp;
      <mat-toolbar color="accent"
             fxLayout="row"
             fxLayoutAlign="start center">
             <div>The Midnight Hour</div>
      </mat-toolbar>
      &nbsp;
      <div class="content"
           fxLayout="row"
           fxLayout.xs="column"
           fxLayoutAlign="space-around stretch">

          <div fxFlex="33" class="sec2">
            <mat-card class="example-card" color="primary">
            <mat-card-header color="primary">
              <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
              <mat-card-title color="primary">Saint Lawn Hill</mat-card-title>
              <!-- <mat-card-subtitle color="accent">The Trouble with Oddo</mat-card-subtitle> -->
            </mat-card-header>
            <!-- <div class="center">  -->
              <img style="width: 80%;" mat-card-image src="assets/illos/TMH_SLH.jpg" alt="In Prison cover">
            <!-- </div> -->
            <mat-card-content>
              <p>Prose stories by James Chambers</p>
              <p>Over 60 illustrations by Jason Whitley</p>
              <p>Out of print</p>
            </mat-card-content>
            <mat-card-actions>
              <a href="http://www.atthemidnighthour.com" mat-flat-button color="accent">View details &raquo;</a>
              <!-- <button mat-button>SHARE</button> -->
            </mat-card-actions>
          </mat-card>
          </div>

          <div fxFlex="33" class="sec2" >
            <mat-card class="example-card">
            <mat-card-header>
              <!-- <div mat-card-avatar class="urchin-header-image"></div> -->
              <mat-card-title>2020 Convention edition</mat-card-title>
              <!-- <mat-card-subtitle>In the Shadow of the Sea Apes</mat-card-subtitle> -->
            </mat-card-header>
            <!-- <div class="center">  -->
              <img style="width: 80%;" mat-card-image src="assets/illos/TMH_Con_cover.png" alt="On Probation cover">
            <!-- </div> -->
            <mat-card-content>
              <p>Collected comics stories by James Chambers and Jason Whitley</p>
              <p>Complete prose & comics edition coming in 2020!</p>
              <p>Available now!</p>
              <!-- <p><a class="btn btn-default" href="http://www.seaurchins.net" role="button">View details &raquo;</a></p> -->
            </mat-card-content>
            <mat-card-actions>
              <a href="http://www.undercurrentart.com/contact.php" target="_blank" mat-flat-button color="accent">View details &raquo;</a>
              <!-- <button mat-button>SHARE</button> -->
            </mat-card-actions>
          </mat-card>
          </div>



  </div>
</div>
&nbsp;
</div><!-- end bounds -->
