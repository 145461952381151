<div class="tab-content">
    <mat-card *ngFor="let illo of illoImages" class="masonry-item" >
      <img class="{{illo.class}}" src="{{illo.picture}}">
      <mat-card-content>
        <p style="margin-top: 10px;">{{illo.title}}</p>
        <p *ngIf="illo.description" style="margin-top: 10px;">{{illo.description}}</p>
        <p *ngIf="illo.link"><a mat-flat-button color="warn" href="{{illo.link}}" target="_blank" role="button">View details &raquo;</a></p>
      </mat-card-content>
    </mat-card>

  <!-- <button mat-flat-button *ngIf="limit < 100" (click)="showMoreImages()">Show More Images</button>
    <h2 *ngIf="limit >= 20">No More Images</h2> -->
</div>
