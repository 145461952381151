import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTabsModule, MatTabGroup } from '@angular/material/tabs';


@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss']
})
export class MasonryComponent implements OnInit {

  @Input() data;
// @ViewChild('tabs', {static: false}) tabGroup: MatTabGroup;
// index=getSelectedTab;
  loadimages=false;
  selectedIndex;
  // masonryItems;
  layout;
  // const tabGroup = this.tabGroup;
// setTimeout(() => {
//   this.loadimages=true;
// }, 1000);

masonryItems = [{
  picture: 'assets/paintings/artichoke_sauce.jpg',
  title: 'Artichoke Sauce',
  class: 'wide'
},
{
  picture: 'assets/paintings/botanical_garden.jpg',
  title: 'Botanical Garden',
  class: 'wide'
},
{
  picture: 'assets/paintings/bridge_study.jpg',
  title: 'Study for bridge',
  class: 'wide'
},
  {
    picture: 'assets/paintings/CHB_small.jpg',
    title: 'Portrait of Charlotte Hawkins Brown',
    class: 'tall'
  },
    {
      picture: 'assets/paintings/cherry_blossoms.jpg',
      title: 'Cherry Blossoms',
      class: 'tall'
    },
      {
        picture: 'assets/paintings/column.jpg',
        title: 'Column',
        class: 'tall'
      },
        {
          picture: 'assets/paintings/derrick_small.jpg',
          title: 'Don Derrick',
          class: 'tall'
        },
          {
            picture: 'assets/paintings/emerald_isle.jpg',
            title: 'Emerald Isle',
            class: 'tall'
          },
              {
                picture: 'assets/paintings/Erica.jpg',
                title: 'Erica',
                class: 'tall'
              },
                {
                  picture: 'assets/paintings/Evan.jpg',
                  title: 'Evan',
                  class: 'wide'
                },
                  {
                    picture: 'assets/paintings/goat.jpg',
                    title: 'Goat',
                    class: 'tall'
                  },
                    {
                      picture: 'assets/paintings/haw_below_bynum_small.jpg',
                      title: 'Haw Below Bynum',
                      class: 'wide'
                    },
                      {
                        picture: 'assets/paintings/haw_dam_small.jpg',
                        title: 'Haw Dam',
                        class: 'wide'
                      },
                        {
                          picture: 'assets/paintings/jonesGap_small.jpg',
                          title: 'Jones Gap',
                          class: 'wide'
                        },
                            {
                              picture: 'assets/paintings/livingRoom.jpg',
                              title: 'Living Room',
                              class: 'wide'
                            },
                              {
                                picture: 'assets/paintings/male_sketch.jpg',
                                title: 'Sketch',
                                class: 'tall'
                              },
                                {
                                  picture: 'assets/paintings/new_hope_riverbank_small.jpg',
                                  title: 'New Hope Riverbank',
                                  class: 'wide'
                                },
                                  {
                                    picture: 'assets/paintings/pearl.jpg',
                                    title: 'Pearl',
                                    class: 'wide'
                                  },
                                    {
                                      picture: 'assets/paintings/peppers.jpg',
                                      title: 'Peppers',
                                      class: 'wide'
                                    },
                                      {
                                        picture: 'assets/paintings/Poets_rest_small.jpg',
                                        title: 'Poet’s Rest',
                                        class: 'wide'
                                      },
                                        {
                                          picture: 'assets/paintings/portrait_in_mirror.jpg',
                                          title: 'Portrait in mirror',
                                          class: 'tall'
                                        },
                                          {
                                            picture: 'assets/paintings/reclining.jpg',
                                            title: 'Reclining Study',
                                            class: 'tall'
                                          },
                                            {
                                              picture: 'assets/paintings/seated-sketch.jpg',
                                              title: 'Seated Sketch',
                                              class: 'tall'
                                            },
                                              {
                                                picture: 'assets/paintings/SeatedStudy.jpg',
                                                title: 'Seated Study',
                                                class: 'tall'
                                              },
                                                {
                                                  picture: 'assets/paintings/shadow_of_a_cityscape.jpg',
                                                  title: 'Shadow of a Cityscape',
                                                  class: 'tall'
                                                },
                                                  {
                                                    picture: 'assets/paintings/Staff.jpg',
                                                    title: 'Study',
                                                    class: 'wide'
                                                  },
                                                    {
                                                      picture: 'assets/paintings/standing_sketch_small.jpg',
                                                      title: 'Standing sketch',
                                                      class: 'tall'
                                                    },
                                                      {
                                                        picture: 'assets/paintings/stones_small.jpg',
                                                        title: 'Stones in the Philosopher’s Way',
                                                        class: 'tall'
                                                      }
                                                    ]

ngOnInit() {
  console.log(this.data)
              // this.selectedIndex = this.tabGroup.selectedIndex;
              this.loadimages=true;
              // this.tabData.layout=true;
              // console.log("paintings");
              //  console.log(this.tabs._tabs._results);
}

}
