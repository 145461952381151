import { Injectable } from "@angular/core";
import { Tab } from "./tab.model";
import { MasonryComponent } from './masonry/masonry.component';
import { PubsComponent } from './pubs/pubs.component';
import { CodingComponent } from './coding/coding.component';
import { IllosComponent } from './illos/illos.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TabService {
  public tabs: Tab[] = [
    new Tab(AboutComponent, "About", { parent: "AppComponent" }),
    new Tab(CodingComponent, "Coding", { parent: "AppComponent" }),
    new Tab(IllosComponent, "Illustration", { parent: "AppComponent" }),
    new Tab(PubsComponent, "Publications", { parent: "AppComponent" }),
    new Tab(MasonryComponent, "Paintings", { parent: "AppComponent", layout: false }),
  ];

  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

  public removeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
      this.tabs[this.tabs.length - 1].active = true;
    }
    this.tabSub.next(this.tabs);
  }

  public addTab(tab: Tab) {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    tab.id = this.tabs.length + 1;
    tab.active = true;
    this.tabs.push(tab);
    this.tabSub.next(this.tabs);
  }
}
