<div class="tab-content">
  <mat-card  class="example-card sec1">
  <mat-card-header>

  </mat-card-header>

  <mat-card-content>
    <div class="content"
         fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="space-around stretch">
<div fxFlex="30"><img style="width:100%; height:auto;" src="assets/crazy.jpg" alt="My nephew"></div>
<div fxFlex></div>
<div fxFlex="68" >
<p>Welcome to Undercurrentart, Jason Whitley's online portfolio for interactive and art work.</p>
<h3>Clients include</h3>
<ul role="list">
<li role="listitem">Sea World</li>
<li role="listitem">The Special Olympics</li>
<li role="listitem">Wal-Mart</li>
<li role="listitem">The N.C. Zoo</li>
<li role="listitem">BellSouth</li>
<li role="listitem">Golf Pa. Magazine</li>
<li role="listitem">Sands Resorts</li>
<li role="listitem">The Washington Post</li>
</ul>
<p>Please visit the tabs and  <a mat-stroked-button href="http://www.undercurrentart.com/contact.php">drop me a line</a>. Thank you for visiting!</p>
</div>
</div>
</mat-card-content>
</mat-card>
</div>
