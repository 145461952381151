/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./illos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i7 from "@angular/material/card";
import * as i8 from "@angular/common";
import * as i9 from "./illos.component";
var styles_IllosComponent = [i0.styles];
var RenderType_IllosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IllosComponent, data: {} });
export { RenderType_IllosComponent as RenderType_IllosComponent };
function View_IllosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_IllosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["color", "warn"], ["mat-flat-button", ""], ["role", "button"], ["target", "_blank"]], [[8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(2, 180224, null, 0, i3.MatAnchor, [i4.FocusMonitor, i1.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["View details \u00BB"]))], function (_ck, _v) { var currVal_5 = "warn"; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); var currVal_1 = (i1.ɵnov(_v, 2).disabled ? (0 - 1) : (i1.ɵnov(_v, 2).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 2).disabled || null); var currVal_3 = i1.ɵnov(_v, 2).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_IllosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "mat-card", [["class", "masonry-item mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i6.View_MatCard_0, i6.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i7.MatCard, [[2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [], [[8, "className", 0], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i7.MatCardContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IllosComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IllosComponent_3)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit.link; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.class, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.picture, ""); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_3); }); }
export function View_IllosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tab-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IllosComponent_1)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.illoImages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IllosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-illos", [], null, null, null, View_IllosComponent_0, RenderType_IllosComponent)), i1.ɵdid(1, 114688, null, 0, i9.IllosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IllosComponentNgFactory = i1.ɵccf("app-illos", i9.IllosComponent, View_IllosComponent_Host_0, {}, {}, []);
export { IllosComponentNgFactory as IllosComponentNgFactory };
