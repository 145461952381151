<!-- Toolbar -->
<mat-toolbar
       fxLayout="row"
       fxLayoutAlign="space-around center">
       <div fxFlex><span style="color:#00695c;">UNDERCURRENTART</span></div>
       <div fxFlex></div><div fxFlex></div><div fxFlex></div><div fxFlex></div>
       <div fxFlex><a color="accent" mat-stroked-button href="http://undercurrentart.com/contact.php">Contact</a></div>
</mat-toolbar>


<!-- //EXPERIMENTAL -->
<!-- <button mat-stroked-button (click)="addNewTab()">Add new tab</button> -->

 <mat-tab-group color="warn"
   class="main-tab-group mt-2"
   [selectedIndex]="selectedTab"
   (selectedTabChange)="tabChanged($event)"
 >
   <mat-tab *ngFor="let tab of tabs; let tabIndex = index">
     <ng-template mat-tab-label
       >{{ tab.title }}
       <!-- <i class="material-icons" (click)="removeTab(tabIndex)">
         close
       </i> -->
     </ng-template>

     <div>
       <app-tab-content [tab]="tab"></app-tab-content>
     </div>
   </mat-tab>
   <!-- <mat-tab label="Paintings" (click)="addNewTab()"></mat-tab> -->
 </mat-tab-group>
<!-- EXPERIMENTAL -->
<!-- <mat-tab-group (selectedTabChange)="tabClick($event)" color="warn"> -->
<!-- <mat-tab-group #tabs color="warn">
  <mat-tab label="About">
    <app-about></app-about>
  </mat-tab>

<mat-tab label="Coding">
  <app-coding></app-coding>
</mat-tab>

<mat-tab label="Publications">
  <app-pubs></app-pubs>
</mat-tab>  -->

<!-- END PUBS -->

<!-- <mat-tab label="Illustration">
  <app-illos></app-illos>
</mat-tab>

<mat-tab label="Painting">
  <div style="height: 100%;">
  <app-masonry></app-masonry> -->
  <!-- <app-paintings></app-paintings> -->
  <!-- </div>
</mat-tab>
</mat-tab-group> -->
<app-footer></app-footer>
<!-- <router-outlet></router-outlet> -->
