/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./content-container.directive";
import * as i2 from "./tab-content.component";
var styles_TabContentComponent = [];
var RenderType_TabContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabContentComponent, data: {} });
export { RenderType_TabContentComponent as RenderType_TabContentComponent };
function View_TabContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_TabContentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { contentContainer: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TabContentComponent_1)), i0.ɵdid(2, 16384, [[1, 4]], 0, i1.ContentContainerDirective, [i0.ViewContainerRef], null, null)], null, null); }
export function View_TabContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tab-content", [], null, null, null, View_TabContentComponent_0, RenderType_TabContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabContentComponent, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabContentComponentNgFactory = i0.ɵccf("app-tab-content", i2.TabContentComponent, View_TabContentComponent_Host_0, { tab: "tab" }, {}, []);
export { TabContentComponentNgFactory as TabContentComponentNgFactory };
